<template>
    <div>
        <article class="index">
            <header>
                <!--banner-slide 广告轮播-->
                <section class="banner-slide banner-cover">
                    <div class="slide-slick">
                        <a :href="item.URL" v-for="(item, key) in carouselData" :key="key">
                            <div :id="'ban' + key" class="slick-ban"
                                 :style="'background-image: url(' + GetImageUrl(item.Image, 1550) + ')'"></div>
                        </a>
                    </div>
                </section>

                <!--/banner-slide 广告轮播-->
            </header>
            <div class="content-con">
                <!--products 产品列表-->
                <section class="products">
                    <ul class="list-prod">
                        <li v-for="(item, key) in homeProductData" :key="key">
                            <div class="prod">
                                <a :href="'product?productId=' + item.Id"
                                   class="prod-photo"
                                   :style="'background-image: url(' +GetImageUrl(item.MainImage, '255') +')'">
                                </a>
                                <section class="prod-info">
                                    <a :href="'product?productId=' + item.Id" class="prod-name">{{item.Title}}</a>
                                    <p class="prod-intro">{{ item.SubTitle }}</p>
                                    <p class="prod-price">
                                        <span class="price-orignal">{{ item.OriginalPrice }}</span>
                                        <span class="price-discount">{{ item.Price }}</span>
                                    </p>
                                    <!--<a href='{Id}' class='btn-addto'></a>-->
                                </section>
                            </div>
                        </li>
                    </ul>
                    <!--pager 页次-->
                    <section class="pager"></section>
                </section>
                <!--/products 产品列表-->
            </div>
        </article>
        <!--大主題分類-->
    </div>
</template>

<script>
    import {
        GetActiveBanners,
        GetAllCategory,
        GetHomeProduct,
        GetRandomProduct,
    } from "@/service/api";
    export default {
        name: "Index",
        data() {
            return {
                carouselData: [],
                categoryData: [],
                homeProductData: [],
            };
        },
        mounted: function () {
            this.$parent.SetWindowTitle("首页");
            GetActiveBanners()
                .then((resp) => {
                    // 隱藏第一個Banner (涉及幣別資訊)
                    resp.data.shift();
                    // 取代 domain
                    resp.data.forEach(function (item, index, array) {
                        let originalDomain = "https://alivestore.azurewebsites.net/category?categoryId=";
                        if (item.URL.includes(originalDomain)) {
                            let newURL = item.URL.replace(originalDomain, `${location.protocol}//${location.host}/Category?prodCateId=`);
                            item.URL = newURL;
                        }
                        else {
                            item.URL = '';
                        }
                    });
                    this.carouselData = resp.data;
                })
                .catch(
                    (data) => {
                        if (data.status === 401) alert("Error: token is not found;");
                        if (data.status === 403) alert("Error: authentication failed;");
                    },

                    GetAllCategory()
                        .then((resp) => {
                            this.categoryData = resp.data;
                            this.categoryData = this.categoryData.sort(
                                (a, b) => a.Card.Position > b.Card.Position
                            );
                        })
                        .catch((data) => {
                            if (data.status === 401) alert("Error: token is not found;");
                            if (data.status === 403) alert("Error: authentication failed;");
                        })
                );
            GetRandomProduct()
                .then((resp) => {
                    this.homeProductData = resp.data;
                })
                .catch((data) => {
                    if (data.status === 401) alert("Error: token is not found;");
                    if (data.status === 403) alert("Error: authentication failed;");
                });
        },
        methods: {
            GetImageUrl: function (imgId, size) {
                var d = new Date();
                var n = d.getTime();
                return (
                    "https://n2imageservice.azurewebsites.net/api/images/shopmall/" +
                    imgId +
                    "/" +
                    size +
                    "/0?" +
                    n
                );
            },
        },
        updated: function () {
            $('[data-toggle="tooltip"]').tooltip();

            $("#accordion .collapse").on("show.bs.collapse", function (e) {
                $("#accordion .in").collapse("hide");
            });

            //$('#accordion').on('show.bs.collapse', function () {
            //    $('#accordion .in').collapse('hide');
            //});

            $(function () {
                // 手機版選單
                // Initialize Slidebars
                var controller = new slidebars();
                controller.init();

                // Toggle Slidebars
                $("#btn-menu").on("click", function (event) {
                    // Stop default action and bubbling
                    event.stopPropagation();
                    event.preventDefault();

                    // Toggle the Slidebar with id 'id-1'
                    controller.toggle("fa-mobile");
                });
            });
        },
    };
</script>

<style>
</style>